import React from 'react';
import { SeriesVideoDetail } from "components/VideoDetail"
import { graphql } from 'gatsby';


export default function VideoDetailTemplate(props) {
  return <SeriesVideoDetail {...props} />
}

export const pageQuery = graphql`
  query SeriesByKeyPlaylistId($mediaId: String!) {
    sitePage(context: {mediaId: {eq: $mediaId}}) {
      context {
        mediaId
        description
        key
        tags
        title
        smallFeaturedImage
        seasonNumber
        episodeNumber
      }
    }
  }
`;
